
import { defineComponent, onBeforeMount, reactive, toRefs, watch } from "vue"

import LayoutDefault from "@/layout/LayoutAdmin"
import Loading from "@/components/Loading"
import BannerItem from "@/components/BannerItem"

import bannersService from "@/services/bannersService"

export default defineComponent({
    components: {
        LayoutDefault,
        Loading,
        BannerItem,
    },

    setup() {
        const state = reactive({
            sections: [],
            activeSection: "",

            list: [],

            page: "1",
            last: "1",

            status: "loading",

            showRemoveConfirmation: false,
        })

        async function fetchSections() {
            state.status = "loading"

            const response = await bannersService.getBannersSections()

            if (response.error) {
                console.error(response)
                
                state.status = "error"
            }
            
            else {
                state.sections = response
                state.activeSection = response[0].value

                state.status = ""
            }
        }

        async function fetchData() {
            state.status = "loading"

            const response = await bannersService.getBannersBySection(state.activeSection)
            
            if (response.error) {
                console.error(response.error)
            }

            else {
                state.list = response.data
                state.last = response.last_page

                state.status = ""
            }
        }

        onBeforeMount(async function () {
          await fetchSections()
          await fetchData()
        })

        watch([
                () => state.activeSection,
                () => state.page,
            ],

            async function() {
                state.status = "loading"

                await fetchData()
            }
        )

        async function remove(id: string) {
            state.status = "loading"

            const response = await bannersService.deleteBannersItem(id)

            if (response.error) {
                console.error(response.error)
            }

            else {
                state.status = ""
                fetchData()
            }
        }

        return {
            ...toRefs(state),
            remove,
        }
    }
})
