
import { defineComponent } from "vue"

export default defineComponent({
    inheritAttrs: false,

    emits: ["remove"],

    props: {
        id: {
            type: [String, Number],
            required: true,
        },

        img: {
            type: String,
            required: true
        },

        title: {
            type: String,
            required: true,
        },

        description: {
            type: String,
            required: true,
        },
    }
})
