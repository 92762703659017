import config from "@/config/config";
import Axios from "axios";

export default new class TrainingService {
  private config = config.getConfig();
  getToken() {
    return localStorage.getItem('__token');
  }

  // GET WEBINARS

  async getBannersBySection(section: string) {
    const token = this.getToken();
    try {
      return (await Axios.get(`${this.config.apiUrl}/banner-section/${section}`,
        {
          headers: {
            'Authorization': `Bearer ${token}`
          }
        })).data;
    } catch (e) {
      if (!e.response) {
        // network error
        return { network: "Network Error" };
      }
      return e.response.data;
    }
  }

  async getBannersDataEcommerce(page: string | number = 1) {
    const token = this.getToken();
    try {
      return (await Axios.get(`${this.config.apiUrl}/banner-section/ecommerce?page=${page}`,
        {
          headers: {
            'Authorization': `Bearer ${token}`
          }
        })).data;
    } catch (e) {
      if (!e.response) {
        // network error
        return { network: "Network Error" };
      }
      return e.response.data;
    }
  }

  // GET PODCAST
  async getBannersDataElearning(page: string | number = 1) {
    const token = this.getToken();
    try {
      return (await Axios.get(`${this.config.apiUrl}/banner-section/elearning?page=${page}`,
        {
          headers: {
            'Authorization': `Bearer ${token}`
          }
        })).data;
    } catch (e) {
      if (!e.response) {
        // network error
        return { network: "Network Error" };
      }
      return e.response.data;
    }
  }

  // GET Banners Types
  async getBannersSections() {
    const token = this.getToken();
    try {
      return (await Axios.get(`${this.config.apiUrl}/banner-sections`,
        {
          headers: {
            'Authorization': `Bearer ${token}`
          }
        })).data;
    } catch (e) {
      if (!e.response) {
        // network error
        return { network: "Network Error" };
      }
      return e.response.data;
    }
  }

  // GET Banners Item
  async getBannersItem(id: string) {
    const token = this.getToken();
    try {
      return (await Axios.get(`${this.config.apiUrl}/banner/${id}`,
        {
          headers: {
            'Authorization': `Bearer ${token}`
          }
        })).data;
    } catch (e) {
      if (!e.response) {
        // network error
        return { network: "Network Error" };
      }
      return e.response.data;
    }
  }

  // DELETE Banners Item
  async deleteBannersItem(id: number | string) {
    const token = this.getToken();
    try {
      return (await Axios.delete(`${this.config.apiUrl}/banner/${id}`,
        {
          headers: {
            'Authorization': `Bearer ${token}`
          }
        })).data;
    } catch (e) {
      if (!e.response) {
        // network error
        return { network: "Network Error" };
      }
      return e.response.data;
    }
  }

  // UPDATE Banners Item
  async updateBannersItem($id: any, $data: any) {
    const token = this.getToken();
    try {
      return (await Axios.put(`${this.config.apiUrl}/banner/${$id}`, $data,
        {
          headers: {
            'Authorization': `Bearer ${token}`
          }
        })).data;
    } catch (e) {
      if (!e.response) {
        // network error
        return { network: "Network Error" };
      }
      return e.response.data;
    }
  }

  // POST Create Banners
  async createBannersItem(data: any) {
    const token = this.getToken();
    try {
      return (await Axios.post(`${this.config.apiUrl}/banner`, data,
        {
          headers: {
            'Authorization': `Bearer ${token}`
          }
        })).data;
    } catch (e) {
      if (!e.response) {
        // network error
        return { network: "Network Error" };
      }
      return e.response.data;
    }
  }

}